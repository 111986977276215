<template>
  <div class="pt-5 mt-5">
    <nav class="navbar navbar-expand-lg fixed-top">
      <div class="container-fluid">
        <router-link class="navbar-brand ps-lg-3" :to="{ name: 'Home' }">
          <img src="../assets/img/brand/logo.png" alt="Bokaklipp logo" />
        </router-link>

        <LangSelect class="me-auto" />
        <div class="mx-2">
          <span class="btn btn-sm btn-primary" @click="copySubscription">{{ $t('general.copyGenericSubscriptionLink') }}</span>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div class="offcanvas-header bg-gray">
            <img
              src="../assets/img/brand/logo.png"
              class="offcanvas-logo ms-2"
              alt="Bokaklipp logo"
            />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-lg-3">
              <li class="nav-item">
                <router-link
                  class="nav-link py-lg-3 px-lg-3"
                  :to="{ name: 'Home' }"
                >
                  <i class="far fa-newspaper nav-item-icon"></i>
                  <span class="nav-item-text">{{
                    $t('general.campaigns')
                  }}</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  v-if="isAdmin"
                  class="nav-link py-lg-3 px-lg-3"
                  :to="{ name: 'Target' }"
                >
                  <i class="far fa-circle-dot nav-item-icon"></i>
                  <span class="nav-item-text">{{
                    $t('general.targetgroups')
                  }}</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  v-if="isAdmin"
                  class="nav-link py-lg-3 px-lg-3"
                  :to="{ name: 'StoreTarget' }"
                >
                  <i class="fas fa-store nav-item-icon"></i>
                  <span class="nav-item-text">{{
                    $t('general.storegroups')
                  }}</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  v-if="isAdmin"
                  class="nav-link py-lg-3 px-lg-3"
                  :to="{ name: 'Dashboard' }"
                >
                  <i class="fa fa-hammer nav-item-icon"></i>
                  <span class="nav-item-text">Admin</span>
                </router-link>
              </li>

              <li class="nav-item d-lg-none">
                <router-link
                  class="nav-link py-lg-3 px-lg-3"
                  :to="{ name: 'Profile' }"
                >
                  <i class="far fa-user nav-item-icon"></i>
                  <span class="nav-item-text">Profile</span>
                </router-link>
              </li>
            </ul>

            <div
              class="d-lg-flex d-none align-items-center cursor-pointer pe-3 profile-pic"
            >
              <div class="" data-bs-toggle="dropdown" aria-expanded="false">
                <img
                  v-if="prevProfile && !previewImageUpdated"
                  src="../assets/logo.png"
                  alt="mdo"
                  class="rounded-circle"
                />
                <img
                  v-else
                  :src="
                    previewImageUpdated ? previewImageUpdated : previewImage
                  "
                  alt="mdo"
                  class="rounded-circle"
                />
              </div>
              <ul class="dropdown-menu dropdown-menu-end" data-offset="0,20">
                <li>
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'Profile' }"
                    >{{ $t('general.profile') }}</router-link
                  >
                </li>

                <!--<li>
									<router-link class="dropdown-item" :to="{ name: 'ArticleDatabase' }">{{
											$t('general.articledatabase')
									}}</router-link>
								</li>-->

                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" @click="onLogOutClick">{{
                    $t('auth.logout')
                  }}</a>
                </li>
              </ul>
            </div>

            <div
              class="d-lg-none d-flex align-items-center position-absolute bottom-0 start-0 w-100 p-3 offcanvas-profile "
            >
              <div class="offcanvas-profilepic">
                <img
                  v-if="prevProfile && !previewImageUpdated"
                  src="../assets/logo.png"
                  alt="mdo"
                  class="rounded-circle"
                />
                <img
                  v-else
                  :src="
                    previewImageUpdated ? previewImageUpdated : previewImage
                  "
                  alt="mdo"
                  class="rounded-circle"
                />
              </div>
              <div
                class="offcanvas-profile-details ms-3 d-flex w-100 align-items-center"
              >
                <div>
                  <h5 class="m-0">
                    {{ userInfo.firstName }} {{ userInfo.lastName }}
                  </h5>
                  <h6 class="text-muted fs-6 m-0">
                    {{ userInfo.companyName }}
                  </h6>
                </div>
                <div @click="onLogOutClick" class="ms-auto offcanvas-logout">
                  <span class="me-2 align-middle">{{ $t('auth.logout') }}</span>
                  <i
                    class="fas fa-arrow-right-from-bracket logout-icon align-middle"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { auth, getMerchantUserProfile } from '@/firebase'
import LangSelect from '@/components/LangSelect.vue'
import { toast } from 'vue3-toastify'

export default {
  name: 'NavBar',
  components: { LangSelect },
  props: {
    previewImageUpdated: {
      type: String,
    },
  },
  setup() {
    return {
      navbarColors: {
        '/': 'red',
        '/Target': 'blue',
      },
    }
  },
  created() {
    auth.currentUser.getIdTokenResult().then(({ claims }) => {
      this.isAdmin = claims.admin
    })
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const { photoData } = await getMerchantUserProfile(user.uid)
          if (photoData) {
            this.previewImage = photoData
            this.prevProfile = false
          } else {
            this.prevProfile = true
          }
        } catch (error) {
          console.log('Could not fetch profile')
        }
      }
    })
  },
  data() {
    return {
      navBarColors: {
        '/': 'red',
        '/target': 'blue',
      },
      isDropped: false,
      isAdmin: false,
      previewImage: '',
      prevProfile: true,
      userInfo: {},
      userId: null,
    }
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.userInfo = await getMerchantUserProfile(user.uid)
        this.userId = user.uid
      }
    })
  },
  methods: {
    onLogOutClick() {
      this.$store.dispatch('logout')
    },
    copySubscription() {
      const currentURL = `${window.location.protocol}//${window.location.host}`;
      const subscriptionLink = `${currentURL}/subscribe/${this.userId}`;
      navigator.clipboard.writeText(subscriptionLink);
      toast.success('Copied to clipboard')
    }
  },
  computed: {},
}
</script>

<style scoped>
.navbar {
  background: #e5e8eb;
}

.navbar-toggler {
  border-radius: 2px;
}

.navbar .navbar-brand img {
  width: 154px;
}

.navbar .profile-pic img {
  width: 54px;
  cursor: pointer;
}

.nav-link {
  font-weight: 700;
  font-family: 'mainFont';
  color: #414141;
}

.offcanvas .nav-item-icon {
  display: none;
}

@media (max-width: 991px) {
  .navbar .offcanvas-logo {
    width: 200px;
    max-width: 60%;
  }

  .offcanvas .offcanvas-body {
    padding-bottom: 120px;
  }

  .offcanvas-profile {
    height: 100px;
    background: #e5e8eb;
  }

  .offcanvas-profile .offcanvas-profilepic img {
    width: 60px;
  }

  .offcanvas .nav-item .nav-link {
    margin-bottom: 2px;
    padding: 11px 10px;
    background: #f4f7fa;
    border-radius: 2px;
    transition: all 0.2s;
    font-weight: 400;
  }

  .offcanvas .nav-item .nav-link:hover {
    background: #e5e8eb;
    margin-left: 5px;
  }

  .offcanvas .nav-item .nav-item-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    width: 22px;
    text-align: center;
  }

  .offcanvas .nav-item .nav-item-text {
    vertical-align: middle;
    margin-left: 10px;
  }

  .offcanvas .offcanvas-logout {
    cursor: pointer;
    color: rgb(32, 183, 248);
  }

  .offcanvas .offcanvas-logout:hover {
    color: rgb(28, 161, 218);
  }

  .offcanvas .offcanvas-logout .logout-icon {
    font-size: 20px;
  }
}
</style>
