<template>
  <div class="dropdown lang-select">
    <button class="btn btn-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <img :src="getLangImg(langs[$i18n.locale].flag)" class="lang-select-flag align-middle me-2" /> <span
        class="align-middle">{{ langs[$i18n.locale].name }}</span>
    </button>
    <ul class="dropdown-menu">
      <li class="dropdown-item" v-for="(lang, index) in langs" :key="index"
        @click="$i18n.locale = lang.code; changeLang(lang)">
        <img :src="getLangImg(lang.flag)" class="lang-select-flag align-middle me-2" />
        <span class="align-middle">{{ lang.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LangSelect',
  data() {
    return {
      langs: {
        se: {
          name: 'Svenska',
          code: 'se',
          flag: 'se',
        },
        en: {
          name: 'English',
          code: 'en',
          flag: 'uk',
        }
      }
    }
  },
  methods: {
    getLangImg(langCode) {
      var images = require.context('../assets/img/flags/', false, /\.svg$/)
      return images('./' + langCode + ".svg")
    },
    changeLang(lang) {
      localStorage.setItem('locale', lang.code);
    }
  },
  computed: {},
}
</script>

<style scoped>
.lang-select .dropdown-menu {
  font-size: 13px;
  min-width: auto;
}

.lang-select button {
  font-size: 13px;
}

.lang-select .lang-select-flag {
  width: 20px;
}

.lang-select .dropdown-item {
  cursor: pointer;
}
</style>
