<template>
  <div :class="{ 'editing-merchant': editingMerchant }">
    <NavBar />
    <div class="container-xxl container-fluid mt-5">
      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <p class="fw-bold mb-1">{{ $t('general.createstoremanually') }}</p>
              <div class="d-flex gap-1">
                <div class="flex-grow-1">
                  <input type="text" class="form-control form-control-md" :placeholder="$t('general.name')"
                    v-model="storeInput" />
                </div>
                <div>
                  <Button class="btn btn-midnight btn-md w-100 px-5" @click="onCreateStore" :loading="isLoading">
                    {{ $t('general.create') }}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 mb-3">
          <div class="card">
            <div class="card-body text-center">
              <p class="fw-bold mb-1">{{ $t('general.addreceiptsmanually') }}</p>
              <Button class="btn btn-midnight btn-md" @click="onClickReceipt" :loading="isLoading">{{
              $t('general.upload')
              }}</Button>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card">
            <div class="card-body">
              <p class="fw-bold mb-1">{{ $t('general.statistics') }}</p>
              <div class="d-flex">
                <div class="flex-fill">
                  <span
                    class="bg-warning text-white p-2 rounded-1 fw-bold align-middle d-inline-block h5 m-0 text-center"
                    style="min-width:38px;">
                    {{ this.userInformation ? this.userInformation.length : 0 }}
                  </span>
                  <span class="align-middle ms-2">{{ $t('general.users') }}</span>
                </div>
                <div class="flex-fill">
                  <span
                    class="bg-warning text-white p-2 rounded-1 fw-bold align-middle d-inline-block h5 m-0 text-center"
                    style="min-width:38px;">{{ this.stores ? this.stores.length : 0 }}</span>
                  <span class="align-middle ms-2">{{ $t('general.storegroups') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div :class="this.editingMerchant ? 'col-lg-4' : 'col-12'">
          <div class="card p-3">
            <div class="card-body bg-gray rounded overflow-hidden">
              <h5 class="card-title me-auto">Översikt</h5>
              <table class="table table-hover mt-4">
                <thead>
                  <tr>
                    <th>{{ $t('general.verified') }}</th>
                    <th>{{ $t('auth.companyname') }}</th>
                    <th scope="col" class="d-none" :class="{ 'd-md-table-cell': !editingMerchant }">
                      {{ $t('general.store') }}
                    </th>
                    <th scope="col" class="d-none" :class="{ 'd-md-table-cell': !editingMerchant }">
                      {{ $t('auth.email') }}
                    </th>
                    <th scope="col" class="d-none" :class="{ 'd-md-table-cell': !editingMerchant }">
                      {{ $t('general.subscription') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in userInformation" :key="user.id" @click="onEdit($event, user)"
                    :class="{ 'active': this.editingId == user.id }">
                    <td style="width:80px;">
                      <input :checked="user.enable == true ? true : false" class="form-check-input" type="checkbox"
                        @click="onEnable(user.id, !user.enable, user.store)" />
                    </td>
                    <td class="text-truncate">{{ user.companyName }}</td>
                    <td class="d-none text-truncate" :class="{ 'd-sm-table-cell': !editingMerchant }">
                      {{ storeName(user.store) ?? '-' }}
                    </td>
                    <td class="d-none text-truncate" :class="{ 'd-md-table-cell': !editingMerchant }">
                      {{ user.email }}
                    </td>
                    <td class="d-none text-truncate" :class="{ 'd-md-table-cell': !editingMerchant }">{{
                    user.subscription
                    }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col position-relative merchant-view">
          <div class="position-absolute left-0 w-100 pe-4 pb-5">
            <div class="card p-3">
              <div class="card-body bg-gray rounded">
                <div class="row mb-4">
                  <div class="col-auto">
                    <div class="d-flex justify-content-center align-items-center rounded mt-2 overflow-hidden"
                      style="width:110px;height:110px;">
                      <img :src="this.editMerchantData.photoData" class="w-100"
                        v-if="this.editMerchantData.photoData" />
                      <span v-else><i class="fa-solid fa-ban text-muted fs-4"></i></span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="subscription-plan mb-3">
                          <img src="../assets/img/subscriptions/default.svg" class="me-2 align-middle"
                            v-show="this.editMerchantData.subscription.toLowerCase() == 'basic'" />
                          <img src="../assets/img/subscriptions/premium.svg" class="me-2 align-middle"
                            v-show="this.editMerchantData.subscription.toLowerCase() == 'premium'" />
                          <small class="align-middle text-muted">{{ this.editMerchantData.subscription }}</small>
                        </div>
                        <select class="form-select rounded-1 border-0 mb-2" @change="onStore(editingId, $event, editMerchantData.enable)">
                          <option selected value="">-</option>
                          <option v-for="store in stores" :value="store.id"
                            :selected="store.id == this.editStoreData.id" :key="store.id">{{ store.name }}</option>
                        </select>
                        <div class="bg-white rounded-1 py-1-5 px-3 mb-1">{{ this.editMerchantData.email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <h6>{{ $t('auth.companydetails') }}</h6>
                  <div class="col-md-4">
                    <div class="mb-1">
                      <label class="fw-bold ms-2">{{ $t('auth.companyname') }}</label>
                      <div class="bg-white rounded-1 py-1-5 px-3">{{ this.editMerchantData.companyName }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-1">
                      <label class="fw-bold ms-2">{{ $t('auth.orgnumber') }}</label>
                      <div class="bg-white rounded-1 py-1-5 px-3">{{ this.editMerchantData.organisationNr }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-1">
                      <label class="fw-bold ms-2">{{ $t('general.address') }}</label>
                      <div class="bg-white rounded-1 py-1-5 px-3 mb-2">{{ this.editMerchantData.address.companyAddress
                      }}
                      </div>
                      <div class="bg-white rounded-1 py-1-5 px-3">{{ this.editMerchantData.address.companyZip }} {{
                      this.editMerchantData.address.companyState
                      }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <h6>{{ $t('auth.personaldetails') }}</h6>
                  <div class="col-md-4">
                    <div class="mb-1">
                      <label class="fw-bold ms-2">{{ $t('general.title') }}</label>
                      <div class="bg-white rounded-1 py-1-5 px-3">{{ this.editMerchantData.title }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-1">
                      <label class="fw-bold ms-2">{{ $t('general.name') }}</label>
                      <div class="bg-white rounded-1 py-1-5 px-3 mb-2">{{ this.editMerchantData.firstName }}</div>
                      <div class="bg-white rounded-1 py-1-5 px-3">{{ this.editMerchantData.lastName }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-1">
                      <label class="fw-bold ms-2">{{ $t('auth.phonenumber') }}</label>
                      <div class="bg-white rounded-1 py-1-5 px-3">{{ this.editMerchantData.phoneNr }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-1">
                      <label class="fw-bold ms-2">SMS-avsändarnamn</label>
                      <div class="bg-white rounded-1 py-1-5 px-3">{{ this.editMerchantData.smsSenderName }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  merchantUserInfo,
  storeTargetCollection,
  updateUserInfo,
  createStore,
  fetchAllCurrentReceipts,
  usersCollection,
} from '@/firebase'

import NavBar from '@/components/NavBar.vue'
import Button from '@/components/misc/Button.vue'
import { toast } from 'vue3-toastify';

export default {
  name: 'Dashboard',
  components: { NavBar, Button },
  data() {
    return {
      users: [],
      storeInput: '',
      isLoading: true,
      userInformation: null,
      stores: [],
      editingMerchant: false,
      editMerchantData: this.emptyMerchantData(),
      editStoreData: {
        id: '',
        name: ''
      },
      editingId: '',
    }
  },
  async created() {
    try {
      this.isLoading = true

      await usersCollection.onSnapshot(async () => {
        this.userInformation = await merchantUserInfo()
        this.isLoading = false
      })

      await storeTargetCollection
        .orderBy('created', 'desc')
        .onSnapshot(async (documents) => {
          documents.forEach((doc) => {
            this.stores.push({
              id: doc.id,
              ...doc.data(),
            })
          })
        })
      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      console.log('Could not load dashboard: ', error)
    }
  },
  methods: {
    emptyMerchantData() {
      return {
        companyName: '',
        organisationNr: '',
        store: '',
        enable: '',
        address: {
          companyAddress: '',
          companyState: '',
          companyZip: '',
        },
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        phoneNr: '',
        subscription: '',
        photoData: '',
      };
    },
    async onEnable(id, enable, store) {
      this.isLoading = true
      store = store ? store : ''
      var data = { uid: id, enable: enable, store: store }
      const result = await updateUserInfo(data)
      this.basicToast(result);
      this.isLoading = false;
    },
    async onStore(id, event, enable) {
      this.isLoading = true
      const store = event.target.value ? event.target.value : ''
      const result = await updateUserInfo({ uid: id, enable: enable, store: store })
      this.basicToast(result);
      this.isLoading = false;
    },
    arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele != value
      })
    },
    onEdit(event, data) {
      if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'BUTTON') {
        if (this.editingId == data.id) {
          this.editingMerchant = false;
          this.editingId = '';
        } else {
          this.editingMerchant = true;
          this.editingId = data.id;
        }

        this.editMerchantData = data;

        this.editStoreData.id = data.store;
        this.editStoreData.name = this.storeName(data.store);
      }
    },
    basicToast(res) {
      if (res.message) {
        res.error ? toast.error(res.message) : toast.success(res.message);
      } else {
        toast.info(res);
      }
    },
    storeName(storeId) {
      let store = this.stores.find(o => o.id === storeId);
      return store ? store.name : null
    },
    async onCreateStore() {
      if (this.storeInput !== '') {
        this.isLoading = true
        const result = await createStore(this.storeInput)
        this.basicToast(result.data);
        this.storeInput = ''
        this.isLoading = false
      }
    },
    async onClickReceipt() {
      this.isLoading = true
      await fetchAllCurrentReceipts()
      toast.info(this.$t('general.receiptsfetched'))
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
* {
  --col-transition: .4s;
}

.row div[class^='col-'] {
  transition: width var(--col-transition) ease-in-out;
}

.merchant-view {
  opacity: 0;
  margin-right: -60%;
  white-space: nowrap;
  transition: all var(--col-transition);
}

.editing-merchant .merchant-view {
  opacity: 1;
  margin-right: 0px;
}

/* .form-check-input {
  margin-left: 0;
}

.accordion-button:not(.collapsed) {
  color: rgb(32, 183, 248);
  background: white;
}

.accordion-button:is(.collapsed) {
  color: white;
  background: rgb(40, 40, 40);
}

select {
  border-width: 0px;
} */

/* .col label {
  text-decoration: underline;
} */
/* .createBtn {
  background: rgb(32, 183, 248);
  color: white;
  justify-items: flex-end;
}

.createBtn:hover {
  color: white;
  background: rgb(28, 161, 218);
}

.header {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.text-primary {
  color: rgb(28, 161, 218) !important;
  font-weight: 700;
  text-transform: uppercase;
}*/

.card-body {
  display: flex;
  min-height: 15vh;
  justify-content: center;
  align-items: flex-end;
} 

/* WIP Dashbored*/
/* .container div {
  transition: width 0.3s !important;
}

.container {
  position: relative;
}

.edit-info {
  position: absolute;
  top: 0;
  left: 35%;
  width: 64%;
  height: 100%;
}

p {
  color: #414141;
}

.company-info {
  position: absolute;
  left: 35%;
  top: 0px;
  width: 64% !important;
}

.col span {
  font-weight: bold;
}

.col p {
  color: #000000;
  border-radius: 2px;
  padding: 8px;
  background: #fff;
}

.loggo {
  height: 133px;
}

.shield {
  height: 20px;
  width: 20px;
  padding: 1px;
  border-radius: 50% 50% 50% 50% / 12% 12% 88% 88%;
} */
</style>