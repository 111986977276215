<template>
  <button :disabled="loading" class="position-relative">
    <div class="position-absolute top-50 start-50 translate-middle">
      <div class="spinner-border spinner-border-sm mt-1" role="status" v-if="loading">
        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
      </div>
    </div>

    <span class="invisible" v-if="loading">
      <slot />
    </span>
    <slot v-else/>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    loading: Boolean
  }
}
</script>

<style scoped>

</style>
